ol,
ul {
    padding-left: 0;
}

dl,
ol,
ul {
    margin-bottom: 0;
}
