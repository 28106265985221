.p-checkbox .p-checkbox-box.p-highlight {
    background: #3c4c54 !important;
    border-color: #3c4c54 !important;
    box-shadow: 0 0 2px #000 !important;
}

.p-checkbox .p-checkbox-box:active {
    background: #3c4c54 !important;
    border-color: #3c4c54 !important;
    box-shadow: 0 0 2px #000 !important;
}
