.jsoneditor {
  .autocomplete {
    &.dropdown {
      position: absolute;
      background: $jse-white;
      box-shadow: $jse-box-shadow;
      border: 1px solid $jse-bar-border;
      overflow-x: hidden;
      overflow-y: auto;
      cursor: default;
      margin: 0;
      padding: 5px;
      text-align: left;
      outline: 0;
      font-family: $jse-font-mono;
      font-size: $jse-font-size;
      .item {
        color: $jse-content-color;
        &.hover {
          background-color: $jse-light-bg;
        }
      }
    }
    &.hint {
      color: $jse-date;
      top: 4px;
      left: 4px;
    }
  }
}
