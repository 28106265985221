.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: var(--dark-grey);
    --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
    --mat-tab-header-pagination-icon-color: #000;
    --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-label-text-color: var(--dark-grey);
    --mat-tab-header-active-ripple-color: var(--dark-grey);
    --mat-tab-header-inactive-ripple-color: var(--dark-grey);
    --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-focus-label-text-color: var(--dark-grey);
    --mat-tab-header-active-hover-label-text-color: var(--dark-grey);
    --mat-tab-header-active-focus-indicator-color: var(--dark-grey);
    --mat-tab-header-active-hover-indicator-color: var(--dark-grey);
}

.mat-mdc-tab-labels {
    height: 90px;
    padding-top: 5px;
    background-color: #f7f8f8;
}

.mat-mdc-tab:not(.mdc-tab--stacked) {
    height: auto !important;
}

.mat-mdc-tab .mdc-tab__text-label {
    font-family: Arial, sans-serif !important;
    display: block !important;
}

.mat-mdc-tab-body-content {
    padding-top: 20px;
}

.mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mdc-tab-indicator__content--underline {
    display: none;
}

.mat-mdc-focus-indicator {
    background: transparent !important;
}

.mat-mdc-tab-labels {
    &:hover .mdc-tab__ripple::before {
        opacity: 0 !important;
    }
}
