.p-dropdown,
.p-multiselect {
    width: 100%;
}

.p-dropdown-item {
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Open Sans',
        'Helvetica Neue',
        sans-serif,
        sans-serif;
    font-weight: 500 !important;
}

.p-dropdown-item:hover {
    background: #eaeaea;
}

.p-dropdown,
.p-multiselect {
    border: solid 1px #d9d9d9;
}

.p-dropdown .p-dropdown-trigger {
    border-left: solid 1px #d9d9d9;
    padding-top: 3px;
    width: 1.5rem;
}

.p-dropdown .p-dropdown-clear-icon {
    font-size: 12px !important;
    margin-top: -0.42rem;
    top: 50%;
}
