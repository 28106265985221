@use '@angular/material' as mat;
@include mat.core();

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import url('https://netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css');

$jse-icons: '/assets/images/jsoneditor-icons.svg';
$jse-blue: #d8400e;
$jse-null: #f5f6f6;
@import 'jsoneditor/src/scss/jsoneditor.scss';

:root {
    --orange: #d8400e;
    --dark-grey: #3c4c54;
}

/**
 * Material UI Override Styles
 */
@import url('material-ui/tabs.scss');
@import url('material-ui/table.scss');

/**
 * Bootstrap Override Styles
 */
@import url('bootstrap/reset.scss');

/**
 * Prime NG Override Styles
 */
@import url('prime-ng/dialog.scss');
@import url('prime-ng/breadcrumb.scss');
@import url('prime-ng/p-toast.scss');
@import url('prime-ng/p-card.scss');
@import url('prime-ng/p-calendar.scss');
@import url('prime-ng/p-dropdown.scss');
@import url('prime-ng/p-datepicker.scss');
@import url('prime-ng/p-button.scss');
@import url('prime-ng/p-checkbox.scss');

/**
 * Base Styles
 */
html {
    font-size: 85%;
    min-height: 100%;
    position: relative;
}

body {
    background-color: white;
    color: #3c4c54;
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin-bottom: 25px;
}

.container-fluid {
    padding-top: 20px;
}

.container {
    max-width: 100%;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    margin: 0 6px;
}

ul {
    list-style: none;
}

.hide {
    display: none;
}

.hidden {
    display: none;
}

[hidden] {
    display: none !important;
}

h4 {
    font-size: 24px;
    font-weight: bold;
}

.padding-right-0 {
    padding-right: 0;
}

.padding-left-0 {
    padding-left: 0;
}

.padding-top-5 {
    padding-top: 5px;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.margin-right-0 {
    margin-right: 0;
}

.margin-left-0 {
    margin-left: 0;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.align-baseline {
    vertical-align: baseline;
}

/**
 * Text
 *
 */
.text-heading {
    color: #3c4c54;
}

.text-white {
    color: #ffffff;
}

/**
 * Bar
 *
 */
.tab-bar {
    height: 90px;
    background-color: #f7f8f8;
}

/**
 * Buttons
 *
 */
.action-button {
    background-color: #3c4c54;
    color: white;
    min-width: 120px;
    margin-bottom: 20px;
    margin-right: 10px;
    font-size: 16px;
    padding: 10px 20px;
    text-align: center;
    &:hover,
    &:focus {
        background-color: rgba(60, 76, 84, 0.8) !important;
        color: white !important;
    }
}

.action-button-small {
    background-color: #3c4c54;
    color: white;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 13px;
    padding: 5px 10px;
    text-align: center;
    &:hover,
    &:focus {
        background-color: rgba(60, 76, 84, 0.8) !important;
        color: white !important;
    }
}

.action-button-small-outlined {
    background-color: #fff;
    border: solid 1px #3c4c54;
    color: #3c4c54;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    padding: 5px 10px;
    text-align: center;
    &:hover,
    &:focus {
        background-color: rgba(60, 76, 84, 0.8) !important;
        color: white !important;
    }
}

.action-button-small-outlined:focus {
    background-color: #fff !important;
    color: #3c4c54 !important;
}

.confirm-btn-container .btn {
    width: 100%;
}

.btn {
    &.btn-no-background {
        color: rgba(60, 76, 84, 0.8) !important;
        background-color: transparent !important;
        &:hover {
            background-image: none !important;
            background-color: rgba(60, 76, 84, 0.8) !important;
            color: white !important;
        }
    }
    &.btn-no-style {
        appearance: none;
        background-color: transparent;
        border: 0 !important;
        width: 30px;
        height: 30px;
        display: block;
    }
    &.icon-delete-bg {
        outline: none !important;
        background-image: url(/assets/images/icon_cancel.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.btn:focus,
.btn:visited {
    color: white;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    opacity: 0.25;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    color: white;
    background-color: #3c4c54;
}

@media (max-width: 991px) {
    .info-header button.action-button {
        margin-top: 20px;
        margin-bottom: 0px;
    }
}

/**
 * Tables
 *
 */
thead {
    height: 43px;
    // background-color: #eff1f1;
    color: white;
}

tbody {
    height: 60px;
    vertical-align: middle;
    background-color: white;
}

th {
    font-weight: bold;
    color: black;
}

.table > tbody > tr > td {
    font-weight: unset !important;
    padding: 8px;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    border-bottom: 1px solid #ccc;
    border-top: none;
    padding: 8px;
}

.td-center {
    text-align: center;
}

/**
 * Link in a Table
 *
 */
.active a.a-link {
    background: none;
}

/**
 * Pages
 *
 */
.page {
    width: 1400px;
}

/**
 * Breadcrumb
 *
 */
div.ui-breadcrumb {
    border-style: none;
    background: none;
}

div.ui-breadcrumb > ul {
    padding-bottom: 2px;
    font-size: 14px;
    list-style: none;
    background-color: #eff1f1;
}

div.ui-breadcrumb > ul a {
    color: #3c4c54;
}

.ui-breadcrumb ul {
    padding-left: 0;
}

/**
 * Spinner
 *
 */
.spinner {
    position: relative;
}

/**
 * Images
 *
 */
.user-active-logo {
    background: url(/assets/images/icon_legend_userActive.png) no-repeat 6px center;
    width: 25px;
    height: 16px;
    display: inline-block;
}

.user-inactive-logo {
    background: url(/assets/images/icon_legend_userInactive.png) no-repeat 6px center;
    width: 25px;
    height: 16px;
    display: inline-block;
}

.user-pendingActivation-legend {
    background: url(/assets/images/icon_legend_userPendingActivation.png) no-repeat 6px center;
    width: 25px;
    height: 16px;
    display: inline-block;
}

.user-pendingActivation-icon {
    background: url(/assets/images/icon_userPendingActivation.png) no-repeat 6px center;
    width: 25px;
    height: 16px;
    display: inline-block;
}

.user-pendingInvite-legend {
    background: url(/assets/images/icon_legend_userPendingInvite.png) no-repeat 6px center;
    width: 25px;
    height: 16px;
    display: inline-block;
}

.user-pendingInvite-icon {
    background: url(/assets/images/icon_userPendingInvite.png) no-repeat 6px center;
    width: 25px;
    height: 16px;
    display: inline-block;
}

/**
 * Popup
 *
 */
.modal-title {
    width: 100%;
    text-align: center;
    color: white;
}

.modal-header {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #3c4c54;
    border-bottom: none;
}

.modal-body {
    padding-bottom: 0;
}

.modal-content {
    border-radius: 0px;
    border-style: none;
}

.modal-footer {
    border-top: none;
    padding-bottom: 0;
}

.modal-footer .action-button {
    margin-top: 0;
    margin-bottom: 15px;
}

.modal-footer .btn + .btn {
    margin-bottom: 15px;
}

/**
 * Misc
 *
 */
.ui-widget-header .ui-button,
.ui-widget-content .ui-button,
.ui-widget.ui-button,
.ui-button {
    border: 1px solid #3c4c54;
    background: #3c4c54;
}

.ui-widget-header .ui-button:enabled:hover,
.ui-widget-header .ui-button:focus,
.ui-widget-content .ui-button:enabled:hover,
.ui-widget-content .ui-button:focus,
.ui-widget.ui-button:enabled:hover,
.ui-widget.ui-button:focus,
.ui-button:enabled:hover,
.ui-button:focus {
    border: 1px solid #637076;
    background: #637076;
}

/**
 * Calendar
 *
 */
.ui-datepicker.ui-widget .ui-datepicker-calendar td a.ui-state-active {
    background-color: #3c4c54;
}

/**
 * Microsoft Edge specific
 *
 */
@supports (-ms-ime-align: auto) {
    table.ui-datepicker-calendar tbody {
        height: unset;
    }
}

/**
 * IE 10 & 11 specific
 *
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    table.ui-datepicker-calendar tbody {
        height: 0;
    }
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 60%;
    }
}

/**
 * Forms
 *
 */
.required-indicator {
    color: red;
}

input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
    border: 1px solid red;
}

/**
 * Accordion
 *
 */
.header-column .card {
    border-style: none;
}

.card-group .card {
    border-radius: 0;
}

.card {
    border-style: none;
}

.header-column.card-group {
    margin-bottom: 0;
}

.card-body.card-block {
    background-color: #e6efff;
}

.header-column .card-default > .card-heading {
    background-color: #e1e7f3;
}

.header-column .card-default > .card-heading:hover {
    background-color: #e1e7f3;
    color: black;
}

.card-default > .card-heading {
    color: #333;
    background-color: #fff;
    border-color: #ddd;
}

.card-default > .card-heading:hover {
    background-color: #f5f5f5;
}

.accordion tbody {
    height: auto;
    display: block;
}

.arrow-right {
    background: url(/assets/images/arrow_right.png) no-repeat 20px center;
    width: 40px;
    height: 16px;
    display: inline-block;
}

.arrow-down {
    background: url(/assets/images/arrow_down.png) no-repeat 20px center;
    width: 40px;
    height: 16px;
    display: inline-block;
}

/**
 * Error Messages
 *
 */
.error-message {
    border: 1px solid #ffbfc2;
    margin-bottom: 20px;
    padding: 10px;
    color: #d8000c;
    background-color: #fff1f1;
    width: 100%;
    padding-bottom: -40px;
    margin-left: 18px;
    border-radius: 4px;
}

.clickable {
    cursor: pointer;
}

/**
 * Main Menu
 *
 */
.navbar {
    padding: 0.5rem 1rem 0.5rem 0.5rem !important;

    li {
        margin-right: 0.5rem;

        @media (max-width: 969px) {
            margin-right: 0;
        }

        .dropdowm-menu {
            margin-right: 0 !important;
        }
    }
}

/**
 * overwrite bootstraps light hamburger menu icon to make it white
 *
 */
.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/**
 * Tab Navigation
 *
 */
ul.nav.nav-pills {
    background-color: #f7f8f8;
    padding: 10px 10px 10px 30px;
    margin-bottom: 20px;
}

.nav-link {
    color: #3c4c54 !important;
}

.admin-comments > label {
    font-size: small;
    color: #3c4c54;
}

.admin-comments textarea {
    margin-top: 0;
}

.nav-pills > li.active > a {
    background-color: #e2e2e2 !important;
}

.nav-pills > li > a:hover {
    background-color: #e2e2e2;
}

.nav.navbar-nav li a:hover {
    background-color: #e2e2e2;
}

.nav.navbar-nav li a {
    color: #3c4c54;
    margin-top: -33px;
    margin-bottom: 2px;
    text-decoration: none;
    margin-right: 8px;
}

@media (min-width: 1200px) {
    .nav.navbar-nav li a {
        margin-right: 20px;
    }
}

.nav .open > .active a,
.active a,
.nav .open > a {
    background-color: #e2e2e2;
}

.nav > li.nav-item > a.nav-item:focus {
    background-color: #e2e2e2;
}

.nav-menu li a br {
    height: 12px;
}

@media (max-width: 513px) {
    .tab-bar {
        height: unset;
    }

    .navbar .container-fluid {
        padding-top: 0;
    }

    .tab-bar .nav > li.nav-item {
        margin-top: 23px;
        margin-bottom: 15px;
    }

    .nav-menu ul.nav.navbar-nav li a {
        margin-top: 0px;
    }
}

@media (max-width: 913px) {
    .admin-comments > label {
        margin-bottom: 0;
        margin-top: 20px;
    }
}

/**
 * Media Component
 *
 */
span.primary-button {
    cursor: pointer;
    background-color: #3c4c54;
    padding: 6px 3px 6px 3px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    line-height: 10px;
    border-radius: 25%;
    float: left;
}

span.trash-button {
    color: #3c4c54;
    padding: 5px 0px 5px 0px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    line-height: 10px;
    margin: 0px 5px;
    float: right;
}

span.download-button {
    color: #3c4c54;
    padding: 5px 0px 5px 0px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    line-height: 10px;
    float: right;
}

span.primary-button:hover {
    background-color: rgba(60, 76, 84, 0.8);
}

span.trash-button:hover,
span.download-button:hover {
    color: rgba(60, 76, 84, 0.8);
}

.ui-inputswitch-on {
    background-color: #3c4c54 !important;
}

.ui-inputswitch .ui-inputswitch-on {
    line-height: 2.4em;
}

.ui-dropdown-panel .ui-dropdown-item,
.ui-multiselect-panel .ui-multiselect-item,
.ui-autocomplete-list .ui-autocomplete-list-item {
    font-size: 1.2em;
    padding: 0.5em 0.5em;
}

.ui-dropdown .ui-dropdown-panel .ui-dropdown-item:not(.ui-state-highlight):hover {
    background: #e2e2e2;
}

.ui-dropdown .ui-dropdown-label {
    font-size: 1.1em;
}

.ui-multiselect-panel .ui-multiselect-item.ui-state-highlight,
.ui-dropdown-panel .ui-dropdown-item.ui-state-highlight,
.ui-autocomplete-list .ui-autocomplete-list-item.ui-state-highlight {
    background: #3c4c54;
}

.ui-multiselect .ui-multiselect-panel .ui-multiselect-item:not(.ui-state-highlight):hover {
    background: #e2e2e2;
}

.ui-multiselect .ui-multiselect-label-container label {
    font-size: 1.1em;
    margin-bottom: 0px;
}

.ui-multiselect-panel .ui-multiselect-item label {
    margin-bottom: 0px;
}

.ui-chkbox .ui-chkbox-box.ui-state-active {
    border: 1px solid #e2e2e2;
    background: #3c4c54;
}

.ui-dropdown .ui-dropdown-trigger .fa,
.ui-multiselect .ui-multiselect-trigger .fa {
    margin-top: 0.55em;
}

.ui-multiselect-header a.ui-multiselect-close {
    background: transparent;
}

.ui-selectbutton .ui-button.ui-state-active {
    background-color: #3c4c54;
}

.filter-selectbutton {
    font-family: Arial;
    padding: 0.4em 1em;
}

/**
 * Typeahead and input
 *
 */
.typeahead-search {
    width: 300px;
    margin-bottom: 20px;
    display: inline-block;
}

/* This is to remove the clear "X" on text inputs */
input[type='text']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type='text']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* This is to remove the up and down arrows on number inputs */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.inline-label.ui-chkbox-label {
    margin-bottom: 0px;
}

.lot-sold-form-wrap {
    &:not(.is-sold) {
        .form-section-label {
            display: none !important;
        }
    }

    .form-section-label {
        display: block;
        max-width: 200px;
        padding-top: 20px;
        border-bottom: 1px solid grey;
        font-weight: 800;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background: none;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled):hover {
        background: none;
    }

    .mat-mdc-form-field-focus-overlay {
        display: none;
    }

    .mat-mdc-form-field-type-checkbox {
        .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
            padding: 0px;
        }

        .mdc-checkbox {
            padding-right: 2px !important;
        }

        .mdc-checkbox__background {
            height: 16px !important;
            width: 16px !important;
        }

        .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
            background: #d8400e !important;
            border-color: #d8400e !important;

            svg {
                color: #fff;
                fill: #fff !important;
            }
        }

        .mdc-checkbox__ripple {
            background: #d8400e !important;
            display: none !important;
        }

        .mdc-checkbox {
            --mdc-ripple-fg-size: 0 !important;
        }

        .mat-mdc-text-field-wrapper {
            height: 30px;
            margin-top: 10px;
            margin-left: -5px !important;
        }

        .mdc-text-field--filled,
        .mdc-text-field--focused {
            --mdc-filled-text-field-active-indicator-height: 0;
        }

        .mdc-line-ripple {
            display: none !important;
        }
    }

    .mat-form-field-type-mat-input {
        margin-top: 10px;
    }

    .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
        border: none !important;
    }

    .mdc-text-field {
        padding: 0;
    }

    .mat-mdc-form-field-input-control {
        font-size: 14px !important;
    }

    .mat-mdc-floating-label {
        font-size: 14px !important;
    }
}

.manage-lots-selector {
    position: relative;
    display: block;
    padding: 10px 15px;
    border-radius: 4px;
    color: #3c4c54;
}

.manage-lots-selector:hover {
    background-color: #e2e2e2;
    color: unset;
}

.start-65 {
    left: 65% !important;
}

label {
    font-weight: 600;
}

/**
 * Links
 *
 */
a {
    color: #337ab7;
    text-decoration: none;
}

.pi {
    font-size: 0.8rem;
    font-weight: bold;
    top: 58%;
}

.modal-dialog {
    border-radius: 6px;
    width: 60%;
    max-width: 60%;
    overflow: hidden;
}

.step-tracker {
    margin: 0 auto;
}

.step-badge-1::before {
    background: none;
}

input:read-only,
input:read-only:focus,
textarea:read-only,
textarea:read-only:focus {
    background-color: #eee;
}

select:read-only,
select:read-only:focus {
    background-color: #fff;
}

.p-inputtext:read-only {
    background: #fff;
}

td.mat-md.hidden {
    display: none;
}
c-cell a {
    cursor: pointer;
    color: #337ab7 !important;
}
