.p-breadcrumb {
    background: unset;
    border: unset;
    padding: 0.5rem;
}

.p-menuitem-link {
    cursor: pointer;
    text-decoration: none !important;
}
