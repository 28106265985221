.mat-mdc-table .mat-mdc-header-cell {
    font-size: 12px;
    font-weight: 550;
    color: #0000008a;
    opacity: 0.85;
    line-height: 1.5;
}

.mat-mdc-table tr:hover {
    background: #f1f1f1;
}

.mat-mdc-table td.mat-mdc-cell a {
    color: #337ab7 !important;
    cursor: pointer;
}
