.p-calendar .p-datepicker {
    top: 39px !important;
}

.p-calendar {
    padding: 0;
    width: 100%;
}

.p-calendar-w-btn .p-inputtext {
    background-color: white !important;
}

.p-calendar-w-btn .p-inputtext:hover {
    border: solid 1px #d9d9d9 !important;
}
