.p-datepicker-buttonbar .p-button {
    background: #fff;
    border: none;
}

.p-button:hover {
    border-color: #828282 !important;
    background: #828282 !important;
    color: #fff;
}

.p-datepicker table td,
.p-datepicker .p-datepicker-header {
    padding: 0.25rem;
}
