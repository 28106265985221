.p-card {
    box-shadow: none;
    border: solid 1px #d9d9d9;
    margin-bottom: 20px;

    .p-card-title {
        font-size: 1.2rem;
        font-weight: 600;
    }

    label {
        font-weight: 600;
    }
}
